import type { Ref } from 'vue'
import { getDocs, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import store from '~/store'
import {
  getCollectionOrdersStatistic,
} from '../firestoreWrappers'
import { errorDefault } from '../helpers/snackbar'
import { mapOrdersStatistic } from '../models/OrdersStatisticModel'

export const useOnlineTransactionReports = defineStore('onlineTransactionReports', () => {
  const data: Ref<any> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<any> = ref(null)

  function resetState() {
    data.value = null
    loading.value = false
    error.value = null
  }

  function getOnlineTransactionReports(company, startDate, endDate, date, city) {
    error.value = null
    loading.value = true

    const onSuccess = ({ docs }) => {
      const copy = { ...(data.value || {}) } || {}
      copy[city] = data.value?.[city] || {}
      copy[city][date] = docs.map(mapOrdersStatistic)

      data.value = copy
      error.value = null
      loading.value = false
    }
    const onError = (errorArg) => {
      console.error(errorArg)
      error.value = errorArg
      loading.value = false
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(errorArg),
      )
    }

    getDocs(getCollectionOrdersStatistic(
      company,
      startDate,
      endDate,
      city,
    ))
      .then(onSuccess)
      .catch(onError)
  }

  function updateOnlineTransactionReports(orderStatistic, onlineTransactionReport) {
    error.value = null
    loading.value = true

    const onSuccess = () => {
      const stringDate = `${orderStatistic.date.toDate().getFullYear()}-${
        (orderStatistic.date.toDate().getMonth() + 1).toString().padStart(2, '0')
      }`
      if (data.value[orderStatistic.city] && data.value[orderStatistic.city][stringDate]) {
        data.value[orderStatistic.city][stringDate] = data.value[orderStatistic.city][stringDate].map((item: any) => {
          if (item.reference.id === orderStatistic.reference.id) {
            return {
              ...item,
              onlineTransactionReports: {
                ...item.onlineTransactionReports,
                ...onlineTransactionReport,
              },
            }
          }

          return item
        })
      }
      error.value = null
      loading.value = false
    }

    const onError = (errorArg) => {
      console.error(errorArg)
      error.value = errorArg
      loading.value = false
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(errorArg),
      )
    }

    updateDoc(orderStatistic.reference, {
      onlineTransactionReports: {
        ...orderStatistic.onlineTransactionReports,
        ...onlineTransactionReport,
      },
    })
      .then(onSuccess)
      .catch(onError)
  }

  return {
    data,
    loading,
    error,
    resetState,
    getOnlineTransactionReports,
    updateOnlineTransactionReports,
  }
})
