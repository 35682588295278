import type { MessageModel } from '../models/MessageModel'
import { serverTimestamp, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { errorDefault } from '~/helpers/snackbar'
import store from '.'
import { getCompanyCateringOrdersQuery, getMessagesInbetweenDatesQuery, getMessagesQuery, getVisibleToDoCateringOrdersQuery, updateMessage } from '../firebase/firestoreMessages'
import { mapMessages } from '../models/MessageModel'

export const useMessages = defineStore('messages', () => {
  const data: any = ref(null)
  const cateringOrdersToDo = ref([])
  const cateringOrdersCompany = ref([])
  const loading = ref(false)
  const unsubscribe1: any = ref(null)
  const unsubscribe2: any = ref(null)

  function resetState() {
    data.value = null
    cateringOrdersToDo.value = []
    cateringOrdersCompany.value = []

    if (unsubscribe1.value)
      unsubscribe1.value()
    if (unsubscribe2.value)
      unsubscribe2.value()
  }

  function getMessages() {
    loading.value = true

    store.dispatch('shared/init')

    const onSuccess = ({ docs }) => {
      data.value = docs.map(mapMessages)
      loading.value = false
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    getMessagesQuery()
      .then(onSuccess)
      .catch(onError)
  }

  function getMessagesInbetweenDates(startDate, endDate) {
    loading.value = true

    store.dispatch('shared/init')

    const onSuccess = ({ docs }) => {
      data.value = docs.map(mapMessages)
      loading.value = false
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    getMessagesInbetweenDatesQuery(startDate, endDate)
      .then(onSuccess)
      .catch(onError)
  }

  function getCateringOrdersToDo() {
    loading.value = true
    store.dispatch('shared/init')

    if (unsubscribe1?.value) {
      unsubscribe1?.value()
      unsubscribe1.value = null
    }

    const onSuccess = ({ docs }) => {
      cateringOrdersToDo.value = docs.map(mapMessages)
      loading.value = false

      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    unsubscribe1.value = getVisibleToDoCateringOrdersQuery(onSuccess, onError)
  }

  function getCompanyCateringOrders(company) {
    loading.value = true
    store.dispatch('shared/init')

    const onSuccess = ({ docs }) => {
      cateringOrdersCompany.value = docs.map(mapMessages)
      loading.value = false

      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    unsubscribe2.value = getCompanyCateringOrdersQuery(company, onSuccess, onError)
  }

  function updateMessageState(userData, item, status) {
    const compareRefId = (element1, element2) => element1?.reference?.id === element2?.reference?.id
    const updateStatus = (itemFunc, statusFunc) => message => (
      compareRefId(
        message,
        itemFunc,
      )
        ? {
            ...message,
            status: statusFunc,
          }
        : message
    )

    loading.value = true

    store.dispatch('shared/init')

    const onSuccess = () => {
      data.value = updateStatus(
        item,
        status,
      )
      loading.value = false
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    updateMessage(
      item,
      status,
      userData,
    )
      .then(onSuccess)
      .catch(onError)
  }

  function updateCateringOrder(userData, item, status, company) {
    loading.value = true

    const onSucces = () => {
      loading.value = false
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    if (status === 'toDo') {
      updateDoc(
        item.reference,
        {
          status,
          company: null,
          isVisible: true,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
        .then(onSucces)
        .catch(onError)
    }
    else {
      updateDoc(
        item.reference,
        {
          status,
          company,
          isVisible: false,
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      ).then(onSucces).catch(onError)
    }
  }

  function changeCategory(userData: any, item: MessageModel, category: string) {
    if (!item.reference)
      return

    loading.value = true

    const onSucces = () => {
      loading.value = false
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      loading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    updateDoc(
      item.reference,
      {
        category,
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )
      .then(onSucces)
      .catch(onError)
  }

  return {
    data,
    cateringOrdersToDo,
    cateringOrdersCompany,
    loading,
    unsubscribe1,
    unsubscribe2,
    resetState,
    getMessages,
    getMessagesInbetweenDates,
    getCateringOrdersToDo,
    getCompanyCateringOrders,
    updateMessageState,
    updateCateringOrder,
    changeCategory,
  }
})
